@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Manrope";
  src: url(../public/fonts/Manrope-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Seagoeprint";
  src: url(../public/fonts/segoeprint_bold.ttf);
}
@font-face {
  font-family: "Avenir Next LT Pro";
  src: url(../public/fonts/AvenirNextLTPro-Regular.ttf);
}
