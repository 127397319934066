/* Hero section landing page */

.herobg::after {
  content: "";
  background: url("../../public/images/herosection/herobg.jpg");
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.aboutbg {
  background: url("../../public/images/aboutus/aboutbg.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.downloadnow {
  box-shadow: 0px 74px 30px rgba(114, 116, 121, 0.01),
    0px 42px 25px rgba(114, 116, 121, 0.04),
    0px 19px 19px rgba(114, 116, 121, 0.07),
    0px 5px 10px rgba(114, 116, 121, 0.08);
}

.inputfield {
  outline: none;
  background: rgba(255, 255, 255, 0.08);
  padding: 12px 14px;
  border-radius: 12px;
  border: 1px solid rgba(225, 231, 250, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ourstorybg {
  background: url("../../public/images/ourstoryhero/bgs.jpg");
  /* background-blend-mode: overlay; */
  background-size: cover;
}

.comingsoonbg {
  background: url("../../public/images/comingsoonbg.png")
    rgba(255, 255, 255, 0.8);
  background-size: cover;
  background-blend-mode: overlay;
}
.resourcebg {
  background: url("../../public/images/tabbackground/resourcesbg.jpg")
    rgba(255, 255, 255, 0.8);

  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: overlay;
}
.communitybg {
  background: url("../../public/images/tabbackground/communitybg.jpg")
    rgba(255, 255, 255, 0.8);

  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: overlay;
}
.newsbg {
  background: url("../../public/images/tabbackground/newsbg.webp")
    rgba(255, 255, 255, 0.8);

  background-repeat: no-repeat;
  background-size: auto;
  background-blend-mode: overlay;
}
.successbg {
  background: url("../../public/images/tabbackground/successbg.jpg")
    rgba(255, 255, 255, 0.8);

  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: overlay;
}
.blogbg {
  background: url("../../public/images/tabbackground/blogbg.png")
    rgba(255, 255, 255, 0.7);

  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: overlay;
}
.partnershipbg {
  background: url("../../public/images/becomeanally/herobanner.webp")
    rgba(255, 255, 255, 0);

  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
}
.blobbg {
  background: url("../../public/images/blog/blog_header.webp")
    rgba(255, 255, 255, 0);

  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: overlay;
}
.donatebg {
  background: url("../../public/images/tabbackground/donate.webp")
    rgba(255, 255, 255, 0.8);

  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: overlay;
}
.helpbg {
  background: url("../../public/images/tabbackground/helpbg.jpg")
    rgba(255, 255, 255, 0.8);

  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: overlay;
}

/* Modal styling  */
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-content {
  background: white;
  border-radius: 24px;
  padding: 40px;
  width: fit-content;
  max-width: 80%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;
}
.playstore::before {
  content: url("../../public/images/footer/Icon.svg");
}
.appstore::before {
  content: url("../../public/images/footer/ic_round-apple.svg");
}

.villageborder {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, #ad6800, #fcf3e6) 1;
}

.missionbg {
  background: linear-gradient(to bottom, #f5f7ff, #ffffff);
}

.arrowicon::after {
  content: url("../../public/images/ourapp/arrow-left.png");
  position: relative;
}

.blogasset::after {
  content: url("../../public/images/blog/blogbg.svg");
  position: absolute;
  top: -3%;
  right: 0;
}

.PhoneInputInput {
  outline: none;
}

@media (max-width: 900px) {
  .herobg::after {
    background: url("../../public/images/herosection/herosectionmobile.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
  }

  .aboutbg {
    background: url("../../public/images/aboutus/aboutmobilebg.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .blogasset::after {
    content: url("../../public/images/blog/blogbgmobile.svg");
    position: absolute;
    top: -1.3%;
    right: 0;
  }

  .ourstorybg {
    background: url("../../public/images/ourstoryhero/hero\ sectionbgmobile.webp");
    /* background-blend-mode: overlay; */
    background-size: cover;
  }
}
